import React from 'react';
import CTAButton from './CTAButton';
import ImageWebp from '../common/ImageWebp';
import {
  CountryTopServiceContent,
  countryTopServiceList,
} from '../../legacy/data_sources/resources/CountryTopServiceList';
import StoreButtons from './StoreButtons';

function FeatureCard(props: CountryTopServiceContent) {
  const { serviceName, title, features, link } = props;
  return (
    <>
      <div className="wa-feature" id={serviceName}>
        <div className="title-outer">
          <div className="container">
            <h2 className="title">{title}</h2>
          </div>
        </div>
        <div className="container">
          <div className="row">
            {features.map((feature, index) => (
              <div key={feature.featureLead} className="col-md-4">
                <div className="wa-feature-item">
                  <ImageWebp
                    className="img-responsive"
                    srcOriginal={feature.featureImages}
                    alt={feature.featureLead}
                  />
                  <p className="lead text-pink">{feature.featureLead}</p>
                  <p className="mb-0 text-body">{feature.featureBody}</p>
                  {serviceName === 'sim' && index === 0 && (
                    <a
                      href="https://wamazing.zendesk.com/hc/en-us/articles/360040380471"
                      rel="noreferrer"
                      target="_blank"
                    >
                      Click here for list of airports.
                    </a>
                  )}
                </div>
              </div>
            ))}
          </div>
          {serviceName === 'sim' ? (
            <div className="mt-3 text-center">
              <p className="lead">Install the WAmazing App Now!!</p>
              <StoreButtons store="Wamazing" />
            </div>
          ) : (
            <div className="mt-3 d-flex justify-content-center">
              <div className="text-center">
                {serviceName === 'snow' && (
                  <p className="lead">
                    Get ski resort information and plan your trip through WAmazing Snow!
                  </p>
                )}
                <CTAButton href={link}>GO TO THE WEBSITE</CTAButton>
              </div>
            </div>
          )}
        </div>
      </div>
      <style jsx>
        {`
          .title-outer {
            padding: 10px 0;
            background-color: #fde5f0;
          }
          .title {
            margin: 0;
            font-weight: 500;
            letter-spacing: -0.03px;
          }
          .text-pink {
            color: #ee4486;
            font-family: NotoSansCJKjp;
            font-weight: bold;
            font-size: 21px;
            letter-spacing: -0.04px;
          }
          .text-body {
            color: #212529;
            font-family: Helvetica;
            font-size: 15px;
            font-weight: 300;
            line-height: 25px;
          }
        `}
      </style>
    </>
  );
}

export default () => (
  <div>
    {countryTopServiceList.map(service => (
      <FeatureCard key={service.serviceName} {...service} />
    ))}
  </div>
);
