import { Button } from '@material-ui/core';
import * as React from 'react';

import { Themes } from '../../shared/styles/StyleConst';

type Props = {
  children: React.ReactNode;
  href: string;
};

export default function CTAButton(props: Props) {
  const { children, href } = props;
  return (
    <Button
      variant="contained"
      className="CTAButton_btn"
      component="a"
      onClick={() => window.open(href, '_blank')}
    >
      {children}
      <style jsx global>
        {`
          .CTAButton_btn {
            background-color: #fe0061 !important;
            color: #fff !important;
            letter-spacing: -0.02px;
            font-size: 14px !important;
            font-weight: bold !important;
            width: 254px !important;
            height: 64px !important;
            border-radius: 3px !important;
            box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.25) !important;
            white-space: nowrap;
          }
          .CTAButton_btn:hover {
            color: #fff !important;
            text-decoration: none !important;
            background-color: ${Themes.background.primary}!important;
          }
        `}
      </style>
    </Button>
  );
}
