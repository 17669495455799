export type TopYadoCardType = 'common' | 'popular' | 'exclusive' | 'area-popular' | 'top-rank';

export function assetImageToWebp(path: string): string {
  if (!path) return '';
  return path.replace(/(\.jpg|\.png)\?/, '$1.webp?');
}

export function resizeImageUrl(path: string | undefined, width: string, height: string) {
  if (!path) return '';

  return path.includes('?') ? `${path}&d=${width}x${height}` : `${path}?d=${width}x${height}`;
}

export function getImageSize(cardType: TopYadoCardType, isMobileWidth: boolean) {
  switch (cardType) {
    case 'common':
      return isMobileWidth ? { width: 158, height: 130 } : { width: 208, height: 165 };
    case 'popular':
      return isMobileWidth ? { width: 173.5, height: 120 } : { width: 278, height: 159 };
    case 'exclusive':
      return isMobileWidth ? { width: 355, height: 252 } : { width: 360, height: 266 };
    case 'area-popular':
      return isMobileWidth ? { width: 94, height: 81 } : { width: 260, height: 192 };
    case 'top-rank':
      return isMobileWidth ? { width: 350, height: 180 } : { width: 260, height: 192 };
  }
}
