import { CSSProperties, ReactEventHandler, SyntheticEvent, useCallback, useState } from 'react';
import R from '../../R';

type ImageLazyProps = {
  alt: string;
  src?: string | null;
  srcSet?: string | null;
  style?: CSSProperties;
  className?: string;
  sizes?: string;
  onError?: ReactEventHandler<HTMLImageElement>;
};

const DEFAULT_TRANSPARENT_IMAGE =
  'data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==';

const ImageLazy = ({
  className = '',
  sizes = '',
  srcSet = null,
  alt,
  src: srcOrigin,
  style,
  onError,
}: ImageLazyProps) => {
  const [isError, setIsError] = useState(false);

  const onErrorHandler = useCallback(
    (e: SyntheticEvent<HTMLImageElement, Event>) => {
      onError?.(e);
      setIsError(true);
    },
    [onError],
  );

  const src = isError || !srcOrigin ? R.assets.images.commonPlaceholderGray : srcOrigin;

  return (
    <img
      src={src}
      srcSet={DEFAULT_TRANSPARENT_IMAGE}
      data-src={src}
      data-srcset={srcSet || src}
      className={`lazyload ${className}`}
      alt={alt}
      data-sizes={sizes}
      style={style}
      onError={onErrorHandler}
    />
  );
};

export default ImageLazy;
