import React from 'react';

import i18n from '../../R/string/i18n';
import R from '../../R';
import { getCompanyInformationUrl } from '../../shared/utils/url_helper';

export default function NextTravel() {
  return (
    <div>
      <div
        className="wa-cta"
        style={{
          height: 300,
          backgroundImage: `url(${R.assets.images.countryTopHeroOkinawa})`,
        }}
      >
        <div className="container">
          <div className="row">
            <div className="col-md-5">
              <p className="text-white title">{i18n.lPNextJapanTravel()}</p>
              <p className="text-white body">
                {i18n.lPThereAreManyAttractiveAreaInJapan()} {i18n.lPWeSuggestYourNextJapanTravel()}
              </p>
              <a className="btn" href={getCompanyInformationUrl()} rel="noreferrer" target="_blank">
                {i18n.lPCompanyInformation()}
              </a>
            </div>
          </div>
        </div>
      </div>
      <style jsx>
        {`
          .text-white {
            color: #fff;
          }
          .title {
            margin-top: 40px;
            font-weight: 300;
            font-size: 2em;
          }
          .body {
            margin-top: 40px;
            line-height: 1.6em;
          }
          div > :global(.btn) {
            padding-left: 24px;
            padding-right: 24px;
            background-color: #fff;
            height: 32px;
            width: auto;
            font-weight: 500;
            font-size: 0.8125rem;
            color: rgba(0, 0, 0, 0.87);
            line-height: 1.75;
            border-radius: 4px;
            letter-spacing: 0.02857em;
            cursor: pointer;
            user-select: none;
            -webkit-tap-highlight-color: transparent;
            border: 0;
            margin: 0;
            display: inline-flex;
            outline: none;
            position: relative;
            align-items: center;
            vertical-align: middle;
            justify-content: center;
            text-decoration: none;
            -webkit-appearance: none;
          }
          div > :global(.btn:hover) {
            background-color: #fff;
          }
        `}
      </style>
    </div>
  );
}
