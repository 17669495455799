import * as React from 'react';
import { assetImageToWebp } from '../../helpers/image_helper';
import ImageLazy from './ImageLazy';

type Props = {
  alt: string;
  srcOriginal: string;
  srcWebp?: string | Null;
  style?: any;
  className?: string;
  sizes?: string;
};

type State = {
  errored: boolean;
};

class ImageWebp extends React.Component<Props, State> {
  static defaultProps = {
    style: {},
    className: '',
    srcWebp: null,
    sizes: '',
  };

  constructor(props: Props) {
    super(props);
    this.state = {
      errored: false,
    };
  }

  get srcSet() {
    const { srcWebp, srcOriginal } = this.props;
    const { errored } = this.state;
    if (errored) {
      // webpファイルがない場合はエラーが発生するので元のファイル(.jpg|.png)にフォールバック
      return srcOriginal;
    }
    // srcWebpの指定がない場合は拡張子.webpを追加
    return srcWebp || assetImageToWebp(srcOriginal);
  }

  render() {
    const { alt, srcOriginal, style, className, sizes } = this.props;
    return (
      <picture>
        <source type="image/webp" srcSet={this.srcSet} />
        <ImageLazy
          src={srcOriginal}
          alt={alt}
          style={style}
          className={className}
          sizes={sizes}
          onError={_e => {
            this.setState({ errored: true });
          }}
        />
      </picture>
    );
  }
}

export default ImageWebp;
