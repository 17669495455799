import * as React from 'react';
import { observer } from 'mobx-react';

import CatchCopy from '../src/components/country_top/CatchCopy';
import Feature from '../src/components/country_top/Feature';
import Hero from '../src/components/country_top/Hero';
import NextTravel from '../src/components/country_top/NextTravel';
import pageComponent from '../src/shared/hoc/pageComponent';
import AuthUserStore from '../src/models/mobx/stores/AuthUserStore';
import i18n from '../src/R/string/i18n';
import OnsenLayout from '../src/components/onsen_yado/layouts/OnsenLayout';
import { pageEvents } from '../src/models/trackings/trackingEvents';
import { trackEvent } from '../src/models/trackings/Tracking';

type Props = {
  authUserStore: AuthUserStore;
  href: string;
};

@pageComponent
@observer
export default class CountryTopIndex extends React.Component<Props, any> {
  componentDidMount() {
    trackEvent(pageEvents.COUNTRY_TOP_PAGE_SHOWN, {
      isSignedIn: this.props.authUserStore?.isSignedIn,
    });
  }

  render() {
    return (
      <OnsenLayout
        productName="wamazing"
        meta={{
          description: i18n.lPDoNotMissOutGreatOffersFromUs(),
          title: `${i18n.webYadoServiceProvider()} - ${i18n.lPEverythingYouNeedToTravelJapan()}`,
          keywords: i18n.lPSiteKeywords(),
          canonical: this.props.href.split('?')[0],
          image: 'https://static.wamazing.com/images/general/og_20180214.jpg',
          noindex: false,
        }}
      >
        <div className="country-top">
          <Hero />
          <CatchCopy />
          <Feature />
          <NextTravel />
        </div>
      </OnsenLayout>
    );
  }
}
