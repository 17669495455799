import R from '../../../R';

export type CountryTopService =
  | 'kaimono'
  | 'onsen'
  | 'activity'
  | 'snow'
  | 'ticket'
  | 'media'
  | 'sim';

type Feature = {
  featureImages: string;
  featureLead: string;
  featureBody: string;
};

export type CountryTopServiceContent = {
  serviceName: CountryTopService;
  title: string;
  features: Feature[];
  link: string;
};

export const countryTopServiceList: CountryTopServiceContent[] = [
  {
    serviceName: 'kaimono',
    title: 'Online Tax-Free Shopping',
    features: [
      {
        featureImages: R.assets.images.countryTopKaimonoFeature01,
        featureLead: 'Reserve products to purchase without having to go to the store',
        featureBody:
          'Do you know what you want to buy, but are not sure where to buy it? With the WAmazing Shop, all you have to do is a quick search and make a reservation to purchase.',
      },
      {
        featureImages: R.assets.images.countryTopKaimonoFeature02,
        featureLead: 'Items reserved for purchase can be picked up close to the airport',
        featureBody:
          'The WAmazing Shop allows you to pick up items reserved for purchase close to the airport. You no longer need to be weighed down carrying around souvenirs while traveling.',
      },
      {
        featureImages: R.assets.images.countryTopKaimonoFeature03,
        featureLead:
          'Done in just 3 minutes! Simplified tax-exemption procedures without the need of contact',
        featureBody:
          'Shop tax-free without having to wait in line to complete tax-free procedures! Simply have your passport ready to verify your identity at the receiving terminal and you will be able to purchase items at tax-free prices.',
      },
    ],

    link:
      'https://www.wamazing.com/kaimono?utm_source=wa-top&utm_medium=referral&utm_campaign=watop',
  },

  {
    serviceName: 'onsen',
    title: 'Onsen Hotel & Ryokan',
    features: [
      {
        featureImages: R.assets.images.countryTopOnsenFeature01,
        featureLead: 'Choose Your Own Meal and Dining Location',
        featureBody:
          'WAmazing allows you to specify the meal plan and dining location of your choice when making a reservation. You can also make requests for allergy accommodations and more via our customer support team.',
      },
      {
        featureImages: R.assets.images.countryTopOnsenFeature02,
        featureLead: 'Request Pick-up Service',
        featureBody:
          'Are you worried about transportation to your accommodation? With WAmazing, you can reserve pick-up service in advance online, or request a pick-up on the day of your stay at the touch of a button via your My Page screen.',
      },
      {
        featureImages: R.assets.images.countryTopOnsenFeature03,
        featureLead: 'Reserve Private Bath',
        featureBody:
          'Are you worried about bathing with other guests in the hot springs? With WAmazing you can easily see if accommodations offer private baths, check open hours, and how to make reservations. You can also make advance reservations via our customer support team.',
      },
    ],

    link:
      'https://www.wamazing.com/yado/onsen?utm_source=wa-top&utm_medium=referral&utm_campaign=watop',
  },

  {
    serviceName: 'activity',
    title: 'Local tours and experiences',
    features: [
      {
        featureImages: R.assets.images.countryTopActivityFeature01,
        featureLead: 'Activities Chosen by Our Staff With In-Depth Knowledge of Japan',
        featureBody:
          'We offer special experiences that only a Japanese travel agency can provide, including activities carefully curated by Japan travel experts, as well as recommendations from our international Japan-based staff.',
      },
      {
        featureImages: R.assets.images.countryTopActivityFeature02,
        featureLead: 'Japanese Cultural Experiences and Exclusive Seasonal Activities',
        featureBody:
          'In addition to cultural experiences like trying on kimonos, Japanese cooking, and traditional craft workshops, we offer unique seasonal activities, including "hanami" cruises to see the cherry blossoms, fireworks festivals, and strawberry picking.',
      },
      {
        featureImages: R.assets.images.countryTopActivityFeature03,
        featureLead: 'Avoid the Lines by Booking Tickets in Advance',
        featureBody:
          'We offer admission tickets to theme parks and popular tourist spots all over Japan. Reserve your tickets online in advance and skip the lines!',
      },
    ],

    link:
      'https://www.wamazing.com/activity?utm_source=wa-top&utm_medium=referral&utm_campaign=watop',
  },

  {
    serviceName: 'snow',
    title: 'WAmazing Snow',
    features: [
      {
        featureImages: R.assets.images.countryTopSnowFeature01,
        featureLead: 'Make the best of your ski trip in Japan',
        featureBody:
          'WAmazing Snow provides detailed information including snow conditions and weather reports.Use WAmazing Snow when planning your ski trip in Japan.',
      },
      {
        featureImages: R.assets.images.countryTopSnowFeature02,
        featureLead: 'Get a great deal when you buy ahead of time',
        featureBody:
          'Ski lessons and lift tickets can be purchased ahead of time on WAmazing Snow. Find offers exclusive to WAmazing Snow or at a lower price than elsewhere before the season begins.',
      },
      {
        featureImages: R.assets.images.countryTopSnowFeature03,
        featureLead: 'Opportunity to get free goods',
        featureBody:
          'Check the ski resort you are interested from time to time for the opportunity to receive free goods.Get them before they sell out.',
      },
    ],

    link: 'https://www.wamazing.com/snow?utm_source=wa-top&utm_medium=referral&utm_campaign=watop',
  },

  {
    serviceName: 'ticket',
    title: 'Transportation Ticket',
    features: [
      {
        featureImages: R.assets.images.countryTopTicketFeature01,
        featureLead: 'Travel all over Japan with an excursion pass!',
        featureBody:
          'We sell Tokyo Wide Passes, Kansai Area Passes, and other great deals on train and bus passes throughout Japan. Choose the ticket that best fits your itinerary and enjoy your trip to Japan at a great price!',
      },
      {
        featureImages: R.assets.images.countryTopTicketFeature02,
        featureLead: 'Purchase an excursion pass in advance to skip the lines!',
        featureBody:
          'If you wait to purchase popular excursion passes such as the Tokyo Wide Pass after arriving in Japan, you may have to wait in long lines. Through us, you can purchase your passes in advance and pick them up at a designated machine for a smooth start to your travels in Japan!',
      },
      {
        featureImages: R.assets.images.countryTopTicketFeature03,
        featureLead: 'A trusted official partner of major Japanese railroad companies',
        featureBody:
          'You can purchase tickets with confidence knowing WAmazing is an official partner of major Japanese railroad companies including JR East, JR West, JR Shikoku, Tokyo Metro, and more.',
      },
    ],
    link:
      'https://www.wamazing.com/ticket?utm_source=wa-top&utm_medium=referral&utm_campaign=watop',
  },
  {
    serviceName: 'media',
    title: 'Japan Travel Guide',
    features: [
      {
        featureImages: R.assets.images.countryTopMediaFeature01,
        featureLead: 'Useful Information for Sightseeing in Japan',
        featureBody:
          'We provide comprehensive, up-to-date information on tourist spots, cuisine, transportation, accommodations, souvenirs, cultural experiences, sample itineraries, seasonal activities, and travel plans for all 47 prefectures in Japan.',
      },
      {
        featureImages: R.assets.images.countryTopMediaFeature02,
        featureLead: 'Travel Information From a Local’s Perspective',
        featureBody:
          'Our native English and Chinese writers residing in Japan share their unique perspectives on Japan’s charms. Our local coverage features new and detailed insights to help you get the most out of your next trip to Japan.',
      },
      {
        featureImages: R.assets.images.countryTopMediaFeature03,
        featureLead: 'Reserve Accommodations, Activities, and More All in One Place',
        featureBody:
          'In addition to travel information, WAmazing serves as a one-stop-shop to book hot spring inns, transportation passes, sightseeing tours, cultural experiences, activities, souvenirs, and more all in one place on our website!',
      },
    ],
    link: 'https://www.wamazing.com/media?utm_source=wa-top&utm_medium=referral&utm_campaign=watop',
  },

  {
    serviceName: 'sim',
    title: 'FREE SIM Card',
    features: [
      {
        featureImages: R.assets.images.countryTopSimFeature01,
        featureLead: 'Receive a SIM card at the airport',
        featureBody:
          'You can receive a SIM card not only at the major airports of Narita, Haneda and Kansai; but also at airports across Japan.',
      },
      {
        featureImages: R.assets.images.countryTopSimFeature02,
        featureLead: 'Free Internet connection',
        featureBody:
          'The free SIM card comes with 500 MB of data traffic. Offering extensive coverage courtesy of DOCOMO, you can have peace of mind when sightseeing outside of the cities.',
      },
      {
        featureImages: R.assets.images.countryTopSimFeature03,
        featureLead: 'Add data anytime',
        featureBody:
          'Purchase additional data traffic anytime. There is also the opportunity to receive free data whenever you make a hotel reservation or through a special offer.',
      },
    ],
    link: 'https://www.wamazing.com/media?utm_source=wa-top&utm_medium=referral&utm_campaign=watop',
  },
];
