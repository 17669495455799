import React from 'react';
import qs from 'qs';

import classnames from 'classnames';
import { getWamazingSubdomain } from '../../config/locales/i18n';
import R from '../../R';
import ImageWebp from '../common/ImageWebp';
import { DeviceContext, DeviceContextType } from '../../models/context/DeviceContext';

function setLinks(nodes: HTMLCollectionOf<Element>, link: string) {
  for (let i = 0; i < nodes.length; i += 1) {
    const node = nodes[i];
    node.setAttribute('href', link);
  }
}

const DEFAULT_ADJUST_PATH = 'mivelp';

type AppStore = 'Wamazing' | 'Aftee';

type Props = {
  store: AppStore;
};

class StoreButtons extends React.Component<Props, any> {
  context!: DeviceContextType;

  static contextType = DeviceContext;

  componentDidMount() {
    const { store } = this.props;
    const { isMobile } = this.context;
    const queries = qs.parse(window.location.search.substr(1));
    const adjustPath = queries.adjust || DEFAULT_ADJUST_PATH;
    // Desktop で AppleStoreリンクの場合は直リンクにする
    // https://wamazing.slack.com/archives/C1Y6968R1/p1522230656000040
    const linkApple =
      store === 'Aftee'
        ? 'https://apps.apple.com/tw/app/aftee-%E5%BE%8C%E6%94%AF%E4%BB%98/id1420585964'
        : isMobile.any
        ? `https://app.adjust.com/${adjustPath}`
        : 'https://itunes.apple.com/app/apple-store/id1181315839?mt=8';
    const linkGoogle =
      store === 'Aftee'
        ? 'https://play.google.com/store/apps/details?id=tw.netprotections.afteeapp'
        : `https://app.adjust.com/${adjustPath}`;
    const buttonApple = document.getElementsByClassName('apple-store');
    const buttonGoogle = document.getElementsByClassName('google-store');
    setLinks(buttonApple, linkApple);
    setLinks(buttonGoogle, linkGoogle);
  }

  render() {
    const { store } = this.props;
    const isChina = getWamazingSubdomain() === 'cn';
    return (
      <span>
        <a
          className={classnames('apple-store wa-cta__link', {
            'is-aftee': store === 'Aftee',
          })}
          href="https://app.adjust.com/mivelp"
          rel="noreferrer"
          target="_blank"
        >
          <ImageWebp srcOriginal={R.assets.images.commonAppStoreStoreApple} alt="Apple App Store" />
        </a>
        {!isChina && (
          <a
            className={classnames('google-store wa-cta__link', {
              'is-aftee': store === 'Aftee',
            })}
            href="https://app.adjust.com/mivelp"
            rel="noreferrer"
            target="_blank"
          >
            <ImageWebp
              srcOriginal={R.assets.images.commonAppStoreStoreAndroid}
              alt="Android Play Store"
            />
          </a>
        )}
      </span>
    );
  }
}

export default StoreButtons;
