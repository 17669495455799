import React from 'react';

import i18n from '../../R/string/i18n';
import R from '../../R';
import ImageWebp from '../common/ImageWebp';
import { Breakpoints } from '../../shared/styles/StyleConst';

export default function CatchCopy() {
  return (
    <div className="wa-feature outer">
      <div className="container">
        <div className="row">
          <div className="col-md-6 offset-md-3">
            <h1 className="wa-section-title">{i18n.lPExcitingJapan()}</h1>
            <p className="wa-section-title--sub">{i18n.lPDoNotMissOutGreatOffersFromUs()}</p>
          </div>
        </div>
        <div>
          <ImageWebp
            srcOriginal={R.assets.images.countryTopJapan}
            className="country-top-catch-japan"
            alt="Japan"
          />
        </div>
      </div>
      <style jsx>
        {`
          .wa-feature {
            margin-bottom: 0;
            padding-bottom: 0;
          }
          .wa-feature :global(.country-top-catch-japan) {
            width: 400px;
            max-width: 100%;
            display: block;
            margin: 10px auto 30px;
          }
          .wa-section-title {
            font-weight: bold;
            font-family: NotoSansCJKjp;
            margin-top: 0;
          }
          .wa-section-title--sub {
            font-size: 21px;
            font-weight: bold;
            font-family: NotoSansCJKjp;
            margin: 0;
          }
          @media screen and (max-width: ${Breakpoints.xsMax}) {
            .wa-section-title {
              font-size: 18px;
            }
            .wa-section-title--sub {
              font-size: 14px;
            }
          }
        `}
      </style>
    </div>
  );
}
