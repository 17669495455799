import React from 'react';
import i18n from '../../R/string/i18n';
import R from '../../R';
import ImageWebp from '../common/ImageWebp';

export default () => {
  return (
    <div className="wa-hero">
      <ImageWebp
        srcOriginal={R.assets.images.countryTopHero}
        alt={`${i18n.webYadoServiceProvider()} top`}
        className="wa-hero__bg_image"
      />
      <div className="wa-hero__overlay">
        <ul className="wa-nav-b">
          <li>
            <a href="#kaimono">Shopping</a>
          </li>
          <li>
            <a href="#onsen">Onsen</a>
          </li>
          <li>
            <a href="#ticket">Ticket</a>
          </li>
          <li>
            <a href="#activity">Experiences</a>
          </li>
          <li>
            <a href="#snow">Snow</a>
          </li>
          <li>
            <a href="#media">Travel Guide</a>
          </li>
          <li>
            <a href="#sim">SIM</a>
          </li>
        </ul>
        <div className="container">
          <img
            className="wa-hero__logo"
            src={R.assets.images.countryTopLogoHorizontalWhite}
            alt={i18n.webYadoServiceProvider()}
          />
        </div>
      </div>
    </div>
  );
};
